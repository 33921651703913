import React from "react";

import {
    setOptions,
    getSession,
    Provider as AuthProvider,
} from "next-auth/client";

//import App from "next/app";
import Head from "next/head";
import Router from "next/router";
import {CookiesProvider} from "react-cookie";

// plugins styles from node_modules
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "@fullcalendar/common/main.min.css";
import "@fullcalendar/daygrid/main.min.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "select2/dist/css/select2.min.css";
import "quill/dist/quill.core.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
// plugins styles downloaded
import "assets/vendor/nucleo/css/nucleo.css";
// core styles
import "assets/scss/nextjs-argon-dashboard-pro.scss?v1.1.0";
import "@sentisso/react-modern-calendar-datepicker/lib/DatePicker.css";
import {AppWrapper} from "../context/state";
import NProgress from "nprogress"; //nprogress module
import "nprogress/nprogress.css"; //styles of nprogress

//Binding events.
Router.events.on("routeChangeStart", () => {
    NProgress.set(0.4);
    NProgress.start();
});
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());
const customerName = process.env.CUSTOMER;

function App(props) {
    const {Component, pageProps, session} = props;
    const Layout = Component.layout || (({children}) => <>{children}</>);
    return (
        <>
            <Head>
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1, shrink-to-fit=no"
                />
                <title>Solar Dash</title>
            </Head>
            <AuthProvider
                options={{site: process.env.NEXTAUTH_URL}}
                session={session}
            >
                <React.Fragment>
                    <AppWrapper>
                        <Layout>
                            <Component {...pageProps} />
                        </Layout>
                    </AppWrapper>
                </React.Fragment>
            </AuthProvider>
            <style jsx global>
                {`


                  .navbar-inner {
                    background: linear-gradient(87deg, #172b4d 0, #142645 100%) !important;
                  }

                  .form-control {
                    margin-bottom: 1rem;
                  }

                  .rstm-tree-item {
                    color: #f6f9fc;
                    font-size: 0.875rem;
                    padding: 0.45rem 1rem;
                    padding-left: 0.2rem;
                    border: none;
                  }

                  .rstm-tree-item-group {
                    padding: 0.2rem;
                  }

                  .rstm-tree-item--active {
                    background: #007bff;
                    border: none;
                  }

                  .rstm-search {
                    height: 2.4rem;
                    border-radius: 2rem;
                    display: table;
                    margin: 0 auto;
                    width: 90%;
                    margin-bottom: 0.5rem;
                  }

                  .dropdown {
                    width: 100%
                  }

                  .some-custom-class {
                    background: white
                  }

                  .slide-pane__subtitle {
                    color: white;
                    margin: auto;
                  }

                  .slide-pane__close svg {
                    color: #32325D;
                  }

                  .slide-pane .slide-pane__title {
                    margin: auto;
                    color: #32325D;
                    font-size: 1rem;
                    font-weight: 600;
                    line-height: 1.375rem;
                  }

                  @media (max-width: 800px) {
                    .sidenav-toggler-dark .sidenav-toggler-line {
                      background-color: black;
                    }
                  }

                  .sidenav-toggler-line {
                    background-color: white;
                  }

                  .sidenav-header {
                    height: 3rem;
                  }

                  .scrollbar-inner {
                    background: linear-gradient(87deg, #172b4d 0, #142645 100%) !important;
                  }

                  .navbar-vertical {
                    background: linear-gradient(87deg, #172b4d 0, #142645 100%) !important;
                    border-style: solid;
                    margin-bottom: 0.5rem;
                  }

                  .slide-pane__overlay {
                    z-index: 120;
                  }

                  .DatePicker {
                    margin: 0rem;
                  }

                  .navbar-top {
                    display: block;
                  }

                  #nprogress {
                    pointer-events: none;
                  }

                  #nprogress .bar {
                    background: #FF8200;
                    position: fixed;
                    z-index: 100031;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 0.3125rem;
                  }

                  #nprogress .peg {
                    display: block;
                    position: absolute;
                    right: 0;
                    width: 100px;
                    height: 100%;
                    box-shadow: 0 0 10px #FF8200, 0 0 5px #FF8200;
                    opacity: 1;
                    -webkit-transform: rotate(3deg) translate(0px, -4px);
                    -ms-transform: rotate(3deg) translate(0px, -4px);
                    transform: rotate(3deg) translate(0px, -4px);
                  }

                  #nprogress .spinner {
                    display: block;
                    position: fixed;
                    z-index: 100031;
                    top: 15px;
                    right: 15px;
                  }

                  #nprogress .spinner-icon {
                    width: 18px;
                    height: 18px;
                    box-sizing: border-box;
                    border: solid 2px transparent;
                    border-top-color: #FF8200;
                    border-left-color: #FF8200;
                    border-radius: 50%;
                    -webkit-animation: nprogresss-spinner 400ms linear infinite;
                    animation: nprogress-spinner 400ms linear infinite;
                  }

                  .nprogress-custom-parent {
                    overflow: hidden;
                    position: relative;
                  }

                  .nprogress-custom-parent #nprogress .spinner,
                  .nprogress-custom-parent #nprogress .bar {
                    position: absolute;
                  }

                  @-webkit-keyframes nprogress-spinner {
                    0% {
                      -webkit-transform: rotate(0deg);
                    }
                    100% {
                      -webkit-transform: rotate(360deg);
                    }
                  }

                  @keyframes nprogress-spinner {
                    0% {
                      transform: rotate(0deg);
                    }
                    100% {
                      transform: rotate(360deg);
                    }
                  }

                  .download1-table-xls-button::before {
                    font-family: "Font Awesome 5 Free";
                    content: "\f019";
                    display: inline-block;
                    padding-right: 3px;
                    vertical-align: middle;
                    font-weight: 600;
                  }

                  .download1-table-xls-button {
                    position: relative;
                    text-transform: none;
                    transition: all 0.15s ease;
                    //border-color: None;
                    box-shadow: None;
                    border: 0;
                    background: none;
                  }


                  .navoptions li a {
                    padding: 1.0625rem 1.25rem;
                    color: white;
                    font-size: 1rem;
                    font-weight: 600;
                    margin-bottom: 0;
                    display: block;
                  }

                  .navoptions li a:hover,
                  .navoptions li a.active {
                    margin-bottom: 0;
                    
                    border-bottom: 0.1875rem solid #FF8200;
                  }

                  .top-nav-buttons {
                    position: absolute;
                    right: 1.875rem;
                    top: 0.8125rem;
                  }

                  .top-nav-buttons .btn {
                    -webkit-box-shadow: none;
                    box-shadow: none;
                    //background: #808080;
                    border: 0;
                  }

                  //.top-nav-buttons .btn:hover {
                  //  //color:  #808080 ;
                  //}
                  .top-nav-buttons .btn:hover,
                  .top-nav-buttons .btn:focus,
                  .top-nav-buttons .btn:active {
                    //background:  #808080 !important;
                    -webkit-transform: none;
                    transform: none;
                    -webkit-box-shadow: none !important;
                    box-shadow: none !important;
                  }

                  @media (max-width: 767.98px) {
                    .top-nav-buttons {
                      right: 0.9375rem;
                    }
                  }

                  .top-nav {
                    padding: 0rem;
                    width: 100%;
                    height: 3.75rem;
                    -webkit-box-shadow: inset 0 -0.0625rem 0 0 #808080;
                    box-shadow: inset 0 -0.0625rem 0 0 #808080;
                  }

                  @media (max-width: 767.98px) {
                    .top-nav {
                      padding: 0 0.9375rem;
                    }

                    .carddivportfolio {
                      max-height: fit-content !important;
                    }

                    .d-xs-block {
                      display: block !important
                    }

                    .backtohome {
                      font-size: 0.9rem;
                      right: 1rem !important
                    }

                    .hiddenheader {
                      display: none !important
                    }

                    .shownheader {
                      font-size: 0 !important;
                      height: 0;
                      padding: 0 !important;
                      line-height: 0;
                      width: 0;
                      min-height: 0 !important;
                      border: 0 !important;
                    }

                    #select-filter-column-Region {
                      margin-top: 1.6rem;
                      position: absolute
                    }

                    #select-filter-column-Client {
                      margin-top: 18px;
                    }

                    .order-4 {
                      display: none
                    }

                    #datatable-basic_filter {
                      font-size: 0
                    }

                    #datatable-basic_filter select {
                      width: 18rem;
                      margin-left: -2rem;
                      height: 45px;
                    }

                    .d-xs-none {
                      display: none
                    }
                  }

                  .customfilter {
                    width: 18rem;
                  }

                  
                  .react-bootstrap-table .sortable .order-4::after, .react-bootstrap-table .sortable .caret-4-desc::after, .react-bootstrap-table .sortable .caret-4-asc::after {
                    left: 0;
                    top: 9px;
                    bottom: 0;
                  }

                  @media (min-width: 767.98px) {
                    .customfilter {
                      position: absolute;
                      top: 12px;
                      right: 0;
                      width: 0;
                      height: 0;
                      appearance: inherit;
                      border: 0;
                      background-size: 75%;
                      background-repeat: no-repeat;
                      background-position: 1px -2px;
                      background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAAxklEQVRIie2VsQ2CQBhGn2hldARHsDBMYWHCHlowhxYMQsEWFoYZTGQDKNEECz8SIIp3gqHhdfff+747igsw8oVJY1303ev0VGhFxOtLQotMqExkIq+ATIGdgb+VmylrhK/QDVi0eHPgKtc3LQeYArGCxxbvJCdWxgoXeAB3YPNmfw3kclzb8pJAN7w0bugAZ+0Fv5YDLIFERfvK/KBZIqcTnsrSyizVzOtaXlJQf+XN9Uf+/pLHA4Y/YGbpW/8vBvkfjNR4AiojK2xKXsIaAAAAAElFTkSuQmCC')
                    }
                  }

                  .togglecolumnlist {
                    width: 14rem;
                    font-size: 1rem;
                    position: absolute;
                    z-index: 9999;
                    background: #fff;
                    padding: 10px;
                    box-shadow: 0px 0px 5px #0000003b;
                    top: 50px;
                    right: 24px
                  }

                  .togglecolumnlist .btn-group {
                    display: block;
                  }

                  .togglecolumnlist .btn {
                    background: transparent !important;
                    color: #403f3f !important;
                    width: 100%;
                    text-align: left;
                    padding: 0;
                    padding-left: 20px;
                    box-shadow: none !important
                  }

                  .togglecolumnlist .btn:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    border: 2px solid #403f3f;
                    width: 15px;
                    height: 15px;
                    margin-top: 2px
                  }

                  .togglecolumnlist .btn.active:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    border: 2px solid #403f3f;
                    width: 15px;
                    height: 15px;
                    margin-top: 2px
                  }

                  .togglecolumnlist .btn.active:after {
                    content: "";
                    left: 5px;
                    top: 4px;
                    width: 5px;
                    height: 9px;
                    border: solid #000;
                    border-width: 0 2px 2px 0;
                    transform: rotate(45deg);
                    position: absolute
                  }

                  .dfdfdfdf {
                    display: none
                  }

                  .columntogglebtn {
                    position: absolute;
                    right: 25px;
                    top: 24px;
                    cursor: pointer
                  }

                `}
            </style>
        </>
    );
}

App.getInitialProps = async (context) => {
    const session = await getSession(context);

    return {
        session,
    };
};

export default App;
