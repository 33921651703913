import React from "react";
import { session, signOut } from "next-auth/client";
import { useSession } from "next-auth/client";
import { Button } from "reactstrap";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

function AdminFooter() {
  const [session, loading] = useSession();
  return (
    <>
      <footer className="footer p-2"  >
        <Container fluid>
          <Row className="align-items-center justify-content-lg-between">
            <Col lg="9">
              <div className="copyright text-center text-lg-left text-muted text-white">
                © {new Date().getFullYear()}{" "}
                <a
                  className="font-weight-bold ml-1"
                  href="https://www.quadrical.ai/"
                  target="_blank"
                  style={{color: "#0eb1a3"}}
                >
                  Quadrical.ai
                </a>
                <p className="text-lead text-white">Signed in as {session.user.email}</p>
              </div>
            </Col>
            <Col lg="3" className="text-lg-right">
              <Button
                className="btn-default"
                onClick={() =>
                  signOut({ callbackUrl: "http://localhost:3000/" })
                }
              >
                Sign out
              </Button>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default AdminFooter;
