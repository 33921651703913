import React, { useState } from "react";
import { withRouter } from "next/router";
import AdminFooter from "components/Footers/AdminFooter.js";
import CustomNavbar from "components/Navbars/customNavbar";
import classnames from "classnames";

import { useSession } from "next-auth/client";
import Login from "pages/index";
import { Collapse, Navbar, NavItem, Nav } from "reactstrap";

import useAppContext from "context/state";
import trim_tree from "plant_variables/trim_tree.json";

let copiedTree = JSON.parse(JSON.stringify(trim_tree));
for (var k = 0; k < copiedTree["nodes"].length; k++) {
  copiedTree["nodes"][k]["nodes"] = [];
}
function Admin(props) {
  const [session, loading] = useSession();
  if (session && session.user.plants) {
  }
  if (session) {
    const api = useAppContext();
    const [sidenavOpen, setSidenavOpen] = React.useState(true);
    const [logoUrl, setLogoUrl] = React.useState('/' + api.logoUrl.data);


    const toggleSidenav = (e) => {
      if (document.body.classList.contains("g-sidenav-pinned")) {
        document.body.classList.remove("g-sidenav-pinned");
        document.body.classList.add("g-sidenav-hidden");
      } else {
        document.body.classList.add("g-sidenav-pinned");
        document.body.classList.remove("g-sidenav-hidden");
      }
      setSidenavOpen(!sidenavOpen);
    };
    const getNavbarTheme = () => {
      return props.router.pathname.indexOf("admin/alternative-dashboard") === -1
        ? "dark"
        : "light";
    };

    return (
      <>
        <div className="main-content" >
          <Navbar
            className={classnames(
              "top-nav navbar-expand top-nav d-flex justify-content-between align-items-center",
              { "navbar-white": getNavbarTheme() === "dark" },
              { "navbar-light bg-secondary": getNavbarTheme() === "light" }
            )}
            style={{ background: "#0B2741", width: "100%", position:"sticky", top:0, zIndex: 99999 }}
          >
            <div className="d-flex align-items-center ">
              <Collapse navbar isOpen={true}>
                <Nav className="align-items-center" navbar>
                  <NavItem className="d-xl-none">
                    <div
                      className={classnames(
                        "ml-md-2 mr-2 p-1 sidenav-toggler text-clr",
                        { active: sidenavOpen },
                        { "sidenav-toggler-dark": getNavbarTheme() === "dark" }
                      )}
                      onClick={toggleSidenav}
                    >
                      <svg className="ic_">
                        <use xlinkHref="#ic_plant" />
                      </svg>
                    </div>
                  </NavItem>
                </Nav>
              </Collapse>
              <img
                src={logoUrl}
                className="cmp-logo mr-2"
                style={{ width: "8rem",  padding:"0.25rem", margin:"0.25rem" }}
              />
              <div className="main-content">
                <CustomNavbar
                />
              </div>
            </div>
          </Navbar>
          {props.children}
          <AdminFooter />
        </div>
        {sidenavOpen ? (
          <div className="backdrop d-xl-none" onClick={toggleSidenav} />
        ) : null}
      </>
    );
  }
  if (loading) return null;

  if (!loading && !session) return <p>Access Denied</p>;

  return (
    <>
      <Login />
    </>
  );
}

export default withRouter(Admin);
