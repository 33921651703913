import React, { createContext, useContext, useState } from "react";
import trim_tree from "plant_variables/trim_tree.json";
import full_tree from "plant_variables/full_tree.json";
import { useSession } from "next-auth/client";
import Login from "pages/index";

const AppContext = createContext();

const populateCurrentDate = () => {
  const today = new Date();
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  return yyyy + "-" + mm + "-" + dd
};

export function AppWrapper(props) {
  const [session, loading] = useSession();
  if (session && !loading) {
    try {
      const children = props.children;
      const treeObj = {
        ...trim_tree,
        nodes: trim_tree["nodes"].filter((value) =>
          session.user.plants["USER"].includes(value["plant_id"])
        ),
      };
      const fullTreeObj = {
        ...full_tree,
        nodes: full_tree["nodes"].filter((value) =>
          session.user.plants["USER"].includes(value["plant_id"])
        ),
      };
      const [currentMeter, setCurrentMeter] = useState(
        treeObj["nodes"][0]["meter_id"]
      );
      var plant_dict = {};
      var plant_id_dict = {};
      for (var k = 0; k < treeObj.nodes.length; k++) {
        plant_dict[treeObj.nodes[k].plant_id] = treeObj.nodes[k].label;
        plant_id_dict[treeObj.nodes[k].label] = treeObj.nodes[k].plant_id;
      }


        var start_d = populateCurrentDate();

        var end_d = populateCurrentDate();
 
      

      const [startDate, setStartDate] = useState(
       start_d
      );
      const [endDate, setEndDate] = useState(
        end_d
      );

  
      const [plant_id, setPlantID] = useState(treeObj["nodes"][0]["plant_id"]);
      const [meterName, setMeterName] = useState("");
      const logoUrl = {
        data:
          session.user.plants.logo == undefined
            ? "solandia.png"
            : session.user.plants.logo,
      };

      const configVariables = {
        data: {
          logo_url:
            session.user.plants.logo == undefined
              ? "solandia.png"
              : session.user.plants.logo,
          audit_flag: session.user.plants.audit,
        },
      };

      
      const onDateChange = (start, end) => {
        setStartDate(start);
        setEndDate(end);
      };
      
      const handleMeterName = (name) => {
        setMeterName(name);
      };

      const onMeterChange = (meter_id, plant_id) => {
        if (typeof window !== "undefined") {
          localStorage.setItem(
            treeObj["nodes"][0]["key"] + "currentMeter",
            meter_id
          );
          localStorage.setItem(treeObj["nodes"][0]["key"] + "plant", plant_id);
        }
        setCurrentMeter(meter_id);
        setPlantID(plant_id);
      };



      const sharedState = {
        onMeterChange,
        currentMeter,
        onDateChange,
        startDate,
        endDate,
        plant_id,
        meterName,
        handleMeterName,
        plant_dict,
        plant_id_dict,
        treeObj,
        fullTreeObj,
        configVariables,
        logoUrl,
      };
      return (
        <>
          <AppContext.Provider value={sharedState}>
            {children}
          </AppContext.Provider>
        </>
      );
    } catch {
      return (
        <>
          <Login />
        </>
      );
    }
  } else if (loading && !session) {
    return <p>Loading User Settings....</p>;
  } else {
    return (
      <>
        <Login />
      </>
    );
  }
}

export default function useAppContext() {
  return useContext(AppContext);
}
